import React, { useState } from 'react';

import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Attribution from '../Attribution/Attribution';
import FlodeskModal from '../Flodesk/SignupModal';
import SEO from '../SEO/SEO';
import './index.scss';
import Banner from '../BannerEvent';

const HeaderNav = ({ location, headerData, headerLogo, hidden }) => {
  const [modalShow, setModalShow] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const isActive = ({ isCurrent }) => {
    return isCurrent ? { className: 'Nav__text_active' } : {};
  };

  const { navItems } = headerData || {};

  const handleNavClick = () => {
    setOpenNav(false);
  };

  return (
    <>
      <SEO location={location} />
      <Attribution />
      {!hidden && (
        <nav className="header-nav">
          <div className={`Nav__left ${openNav && 'open'} no-border`}>
            <div
              onClick={() => setOpenNav(!openNav)}
              className={`navbar__iconMobile d-xl-none left-0 ${openNav && 'open'
                }`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Button
              className="purple-button nav-button"
              onClick={() => setModalShow(true)}
            >
              Get Started
            </Button>
            <Link to="/">
              {headerLogo && (
                <img
                  src={headerLogo.url}
                  alt={headerLogo.title}
                  className="sequin-logo"
                />
              )}
            </Link>
          </div>
          <div className="Nav__right">
            <div className={`Nav__rightLink ${openNav && 'show'}`}>
              {navItems?.map((item, index) => (
                <Link
                  key={index}
                  className="Nav__text"
                  getProps={isActive}
                  to={item.link}
                  onClick={handleNavClick}
                >
                  {item.text}
                </Link>
              ))}
              <a
                className="Nav__text"
                onClick={e => {
                  e.preventDefault();
                  setModalShow(true);
                }}
                href="#"
              >
                SIGN UP
              </a>
              <a
                className="Nav__text"
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.sequincard.com"
              >
                LOGIN
              </a>
            </div>
          </div>
        </nav>
      )}
      {/* <Banner /> */}
      <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

HeaderNav.propTypes = {
  location: PropTypes.object,
  headerData: PropTypes.object,
  headerLogo: PropTypes.object
};

export default HeaderNav;
